import React from "react"

import Layout from "../../components/layout"
import SubServices from "../../components/screens/Services/SubServices"
import { Helmet } from "react-helmet"

const WebDevelopmentPage = () => (
  <Layout pageInfo={{ pageName: "Web Development" }} sitePage="site-page">
    <Helmet defer={false}>
        <title>Web Development</title>
        <meta name="description" content="Web Development" />
    </Helmet>
    <h1 className="name-sub-services">Web Development</h1>
    <div className="wrapper-box-service">
      <SubServices
        left
        image={require("../../images/jpg/sub-services.jpg")}
        normal="All of our Web designs are optimized for a fluid User Experience from landing page to checkout. Our designs are focused on providing the user with clear messaging and strong calls-to-action to push them to take action. Our interaction design meets functionality to create usability and ultimately conversions. User interfaces are intuitively designed, pages load quickly, and checkout is a breeze."
      />
      <SubServices
        image={require("../../images/jpg/sub-services-2.jpg")}
      >
        <p className="normal-text">
          + Website Design: Our creative design creates your visually stunning and practical website.<br/>
          + E-commerce Design: Your ecommerce websites will be designed to increase qualified traffic, and convert your visitors into customers.<br/>
          + Responsive Web Design: Our Responsive Design prepares your websites beautiful across multiple devices.<br/>
          + Template Design: We provide template design or customize the designs over the templates prior to developing one to suit specifically the business requirement<br/>
        </p>
      </SubServices>
    </div>
  </Layout>
)

export default WebDevelopmentPage
